








































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import utils from "@/libs/utils";

@Component
export default class MerchantFeeSettingDialog extends Vue {
  dialogFormVisible: boolean = false;
  formData: any = {
    chargingBatteryRentPrice: "",
    chargingBatteryFreeMinutes: "",
    chargingBatteryRentTimeUnit: "",
    chargingLineRentPrices: [
      {
        minutes: '',
        price: ''
      }
    ],
    isMonthlySubscription: true,
    monthlyFee: 0,
    monthlyFeeStartDate: "",
    monthlyFeeEndDate: "",
    id: 0
  };
  loading: boolean = false;
  config:any={}
  rules: any = {
    monthlyFeeStartDate: [
      { required: true, message: "请输入选择开始计费时间", trigger: "blur" }
    ],
    monthlyFeeEndDate: [
      { required: true, message: "请输入选择结束计费时间", trigger: "blur" }
    ]
  };

  show(id: number, feeInfo: any) {
    console.log(feeInfo);
    this.dialogFormVisible = true;
    var chargingLineRentPrices =
      feeInfo.chargingLineRentPrices.length > 0
        ? feeInfo.chargingLineRentPrices
        : [
            {
              minutes: '',
              price: ''
            }
          ];
    this.formData = {
      ...feeInfo,
      chargingLineRentPrices,
      id
    };
  }
  created() {
    this.gainData()
  }
//   获取数据
 async gainData(){
    try {
        let res= await this.$ajax.get('/api/services/app/Configuration/GetClientConfig',{
            params:{name:'clientConfig'}
        })   
        if(res.data.success){
                this.config =res.data.result
                console.log(this.config,'******');
                
            }
      } catch (error) {
          console.log(error);
      }
  }
//   提交
  submit() {
    (this.$refs["roleForm"] as any).validate(async (valid: boolean) => {
      if (valid) {
        // this.$emit("confirm", utils.clone(this.formData));
        this.formData.chargingLineRentPrices.map((e:any)=>{
           if(e.minutes===''||e.price===''){
               this.formData.chargingLineRentPrices=[]
           }
        })
        this.loading = true;
        try {
          var res = await this.$ajax.post(
            "/api/services/app/Merchant/SetFree",
            this.formData
          );
          if (res.data.success) {
            this.$notify.success("设置成功");
            this.dialogFormVisible = false;
            this.$emit("confirm");
          }
        } catch (error) {}
        this.loading = false;
      }
    });
  }
  get chargingLineFeeOptions() {
    return this.config.chargeLineCost
  }
  get chargingLineTimeOptions() {
    return this.config.chargeLineTime
  }
//   添加充电线套餐
  addChargingLineFee(index: number) {
    this.formData.chargingLineRentPrices.push({
      minutes: "",
      price: ""
    });
  }
//   移除充电线套餐
  removeChargingLineFee(index: number) {
    this.formData.chargingLineRentPrices.splice(index, 1);
  }
  get chargingBatteryRentTimeOptions() {
    return this.config.chargingTime
  }
  get chargingBatteryPriceOptions() {
    return this.config.chargeTreasureCharge
  }
  get chargingBatteryFreeTimeRange() {
    return this.config.theFreeTime
  }
}
