







































import { Component, Vue, Watch } from "vue-property-decorator";
import utils from "@/libs/utils";

@Component
export default class MerchantRoleUserDialog extends Vue {
  dialogFormVisible: boolean = false;
  formData: any = {
    memberId: 0,
    distributionRate: 0,
    role: 1
  };
  loading: boolean = false;
  allowManage: boolean = false;
  isEdit: boolean = false;
  rules: any = {
    memberId: [{ required: true, message: "请选择会员", trigger: "change" }],
    distributionRate: [
      { required: true, message: "请输入分红比例", trigger: "blur" }
    ]
  };

  memberSearchLoading: boolean = false;
  memberItems: any[] = [];
  async readerMemberOptions(query: string) {
    this.memberSearchLoading = true;
    try {
      var res = await this.$ajax.post(
        "/api/services/app/Member/QueryPagedList",
        {
          page: 1,
          size: 10,
          keywords: query
        }
      );
      this.memberItems = res.data.result.items;
    } catch (error) {}
    this.memberSearchLoading = false;
  }
  show(allowManage: boolean) {
    this.dialogFormVisible = true;
    this.allowManage = allowManage;
    this.formData = {
      memberId: "",
      distributionRate: "",
      role: 0
    };
  }
  hide() {
    this.dialogFormVisible = false;
  }
  submit() {
    console.log(this.formData);
    
    (this.$refs["roleForm"] as any).validate(async (valid: boolean) => {
      if (valid) {
        this.$emit("confirm", utils.clone(this.formData));
      }
    });
  }
}
