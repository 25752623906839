// const core = require('./core'),
//   utils = require('./utils')
import utils from './utils'
import elementUI from 'element-ui'
import axios from 'axios'
import { remoteServiceBaseUrl } from './appconst'
let _fullProvinces: any = null,
  _fullCitys: any = null,
  _fullCountys: any = null

  // 获取省
export const getProvinces = () => {
  return utils.clone(_fullProvinces)
}
// 获取市
export const getCitys = (provinceCode: string) => {
  return utils.clone(_fullCitys[provinceCode])
}
// 获取区
export const getCountys = (cityCode: string) => {
  return utils.clone(_fullCountys[cityCode])
}

export const getProvinceByCode = (code: string) => {
  var provice = _fullProvinces.find((item: any) => item.id == code)
  return utils.clone(provice)
}

export const getCityByCode = (code: string) => {
  var provinceCode = `${code}`.substr(0, 2) + '0000000000'
  var city = _fullCitys[provinceCode].find((item: any) => item.id == code)
  return utils.clone(city)
}

export const getCountyByCode = (code: string) => {
  var cityCode = `${code}`.substr(0, 4) + '00000000'
  var county = _fullCountys[cityCode].find((item: any) => item.id == code)
  return utils.clone(county)
}

const _request = async (url: string) => {
  try {
    var res = await axios.get(`${remoteServiceBaseUrl}${url}`)
    if (res.status == 200) {
      return res.data
    }
  } catch (error) {}
  return []
}

// 初始化 
export const init = async () => {
  _fullProvinces = await _request('/areas/province.json')
  _fullCitys = await _request('/areas/city.json')
  _fullCountys = await _request('/areas/county.json')
}
