















































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";

import * as appRegion from "@/libs/app_region";

import MerchantRoleUserDialog from "./components/MerchantRoleUserDialog.vue";
import MerchantFeeSettingDialog from "./components/MerchantFeeSettingDialog.vue";
import MerchantStaffDialog from "./components/MerchantStaffDialog.vue";
import utils from "../../../libs/utils";

@Component({
  components: {
    MerchantRoleUserDialog,
    MerchantFeeSettingDialog,
    MerchantStaffDialog,
  },
})
export default class MerchantDetails extends Vue {
  loading: boolean = false;
  id!: any;
  merchantData: any = null;
  Dates: any[] = [new Date(), new Date()]; //保存数组
  merchantAddress: string = "";
  merchantIndustry: any[] = [];
  feeInfo: any = null;
  merchantRoles: any[] = [];
  staffs: any[] = [];
  pagerHeader: any = {
    title: "商户详细",
    desc: "商户详细",
    breadcrumb: ["商户管理", "商户详细"],
  };
  rules: any = {
    name: [{ required: true, message: "请输入商家名称", trigger: "blur" }],
    categoryId: [{ required: true, message: "请选择行业", trigger: "blur" }],
    internalContractTel: [
      { required: true, message: "请填写手机号", trigger: "blur" },
      {
        pattern: /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/,
        message: "手机号不正确",
      },
    ],
    contractName: [
      { required: true, message: "请输入联系人", trigger: "blur" },
    ],
    contractTel: [
      { required: true, message: "请填写电话", trigger: "blur" },
      {
        pattern: /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/,
        message: "电话不正确",
      },
    ],
    "address.street": [
      { required: true, message: "请填写地址", trigger: "blur" },
    ],
    "placementPositions[0]": [
      { required: true, message: "请输入放置位置", trigger: "blur" },
    ],
    imageUrl: [{ required: true, message: "请上传图片", trigger: "blur" }],
  };
  async readerMerchantInfo() {
    this.loading = true;
    try {
      var res = await this.$ajax.get("/api/services/app/Merchant/GetInfo", {
        params: {
          id: this.id,
        },
      });
      var data = res.data.result;
      this.merchantData = data;
      this.Dates[0] = this.merchantData.openTime;
      this.Dates[1] = this.merchantData.closeTime;

      this.merchantAddress = `${
        appRegion.getProvinceByCode(data.address.provinceCode).name
      } 
      ${appRegion.getCityByCode(data.address.cityCode).name} 
      ${appRegion.getCountyByCode(data.address.areaCode).name} `;
    } catch (error) {}
    this.loading = false;
  }
  async readerFeeInfo() {
    this.loading = true;
    try {
      var res = await this.$ajax.get(
        "/api/services/app/Merchant/GetFreeSetInfo",
        {
          params: {
            id: this.id,
          },
        }
      );
      if (res.data.success) {
        this.feeInfo = res.data.result;
      }
    } catch (error) {}
    this.loading = false;
  }
  async readerRoles() {
    this.loading = true;
    try {
      var res = await this.$ajax.get(
        "/api/services/app/Merchant/GetRoleSetInfo",
        {
          params: {
            id: this.id,
          },
        }
      );
      if (res.data.success) {
        var data = res.data.result;
        this.merchantRoles = [];
        if (data.manager) {
          this.merchantRoles.push({
            ...data.manager,
            distributionRate: data.managerDistributionRate,
            distributionRateValue: data.managerDistributionRate * 100,
            roleDesc: "负责人",
            role: 1,
            edit: false,
          });
        }
        data.shareholders.forEach((item: any) => {
          this.merchantRoles.push({
            ...item.member,
            distributionRate: item.distributionRate,
            distributionRateValue: item.distributionRate * 100,
            roleDesc: "分红人",
            role: 0,
            edit: false,
          });
        });
      }
    } catch (error) {}
    this.loading = false;
  }
  async readerStaffs() {
    this.loading = true;
    try {
      var res = await this.$ajax.get("/api/services/app/Merchant/GetStaffs", {
        params: {
          id: this.id,
        },
      });
      if (res.data.success) {
        this.staffs = res.data.result;
      }
    } catch (error) {}
    this.loading = false;
  }
  //   修改图片
  handleBannerImageSuccess(res: any, file: any) {
    // this.imageUrl = URL.createObjectURL(file.raw);
    console.log(res);
    if (res.success) this.merchantData.imageUrl = res.result.fileUrl;
  }
  beforeUpload(file: any) {
    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      this.$message.error("上传头像图片大小不能超过 2MB!");
    }
    return isLt2M;
  }
  // 查看图片、
  imgFuc(url: string) {
    window.open(url);
  }
  //   获取行业类别
  async readerTrade() {
    try {
      var res = await this.$ajax.get("/api/services/app/Category/GetAll", {
        params: {
          type: 2,
        },
      });
      if (res.data.success) {
        this.merchantIndustry = res.data.result;
      }
    } catch (error) {}
  }
  async initPage() {
    this.id = this.$route.query.id;
    await this.readerMerchantInfo();
    await this.readerFeeInfo();
    await this.readerRoles();
    await this.readerStaffs();
    await this.readerTrade();
  }
  created() {
    this.initPage();
  }
  activated() {
    if (this.id != this.$route.query.id) {
      this.merchantRoles = [];
      this.initPage();
    }
  }
  // 用户选择了时间
  changDate() {
    this.merchantData.openTime = this.Dates[0];
    this.merchantData.closeTime = this.Dates[1];
  }
  async roleConfirm(roleUser: any) {
    // 传过来的数据
    var roles = this.getRoles();
    console.log(roleUser);

    console.log(roles, "roles");

    if (roleUser.role == 1) {
      roles.managerId = roleUser.memberId;
      roles.managerDistributionRate = roleUser.distributionRate / 100;
    } else {
      roles.shareholders.push({
        memberId: roleUser.memberId,
        distributionRate: roleUser.distributionRate / 100,
      });
    }
    (this.$refs.merchantRoleUserDialog as any).loading = true;
    try {
      var res = await this.$ajax.post("/api/services/app/Merchant/SetRoles", {
        ...roles,
      });
      if (res.data.success) {
        this.readerRoles();
        (this.$refs.merchantRoleUserDialog as any).hide();
      }
    } catch (error) {}

    (this.$refs.merchantRoleUserDialog as any).loading = false;
  }
  // 保存修改
  async onSubmit() {
    console.log(this.merchantData);
    (this.$refs.merchantData as any).validate(async (valid: boolean) => {
      if (valid) {
        try {
          var res = await this.$ajax.post(
            "/api/services/app/Merchant/CreateOrUpdate",
            this.merchantData
          );
          if (res.data.success) {
            this.$notify.success("修改成功");
            this.readerRoles();
          }
        } catch (error) {}
      } else {
        console.log("error submit!!");
        return false;
      }
    });
  }
  getRoles() {
    console.log(this.merchantRoles);
    var data = {
      managerId: null,
      managerDistributionRate: 0,
      shareholders: this.merchantRoles
        .filter((m) => m.role == 0)
        .map<any>((m) => {
          return {
            memberId: m.id,
            distributionRate: m.distributionRate,
          };
        }),
      id: this.id,
    };
    var manager = this.merchantRoles.find((m) => m.role == 1);
    if (manager) {
      data.managerId = manager.id;
      data.managerDistributionRate = manager.distributionRate;
    }
    return data;
  }
  async deleteRole(index: number) {
    var result = await this.$confirm("确认删除该角色吗?");
    if (result == "confirm") {
      this.merchantRoles.splice(index, 1);
      try {
        var res = await this.$ajax.post(
          "/api/services/app/Merchant/SetRoles",
          this.getRoles()
        );
        if (res.data.success) {
          this.$notify.success("删除成功");
          this.readerRoles();
        }
      } catch (error) {}
    }
  }
  openAddRole() {
    (this.$refs.merchantRoleUserDialog as any).show(
      !this.merchantRoles.find((m) => m.role == 1)
    );
  }
  async staffConfirm(staff: any) {
    var staffs = utils.clone(this.staffs);
    staffs.push(staff);
    (this.$refs.merchantStaffDialog as any).loading = true;
    try {
      var res = await this.$ajax.post("/api/services/app/Merchant/SetStaffs", {
        staffs: staffs,
        id: this.id,
      });
      if (res.data.success) {
        (this.$refs.merchantStaffDialog as any).hide();
        this.$notify.success("保存成功");
        this.readerStaffs();
      }
    } catch (error) {}
    (this.$refs.merchantStaffDialog as any).loading = false;
  }
  async deleteStaff(index: number) {
    var result = await this.$confirm("确认要删除该员工吗");
    if (result == "confirm") {
      var staffs = utils.clone(this.staffs);
      staffs.splice(index, 1);
      var loading = this.$loading({ text: "删除中..." });
      try {
        var res = await this.$ajax.post(
          "/api/services/app/Merchant/SetStaffs",
          {
            staffs: staffs,
            id: this.id,
          }
        );
        if (res.data.success) {
          this.$notify.success("删除成功");
          this.readerStaffs();
        }
      } catch (error) {}
      loading.close();
    }
  }
  editRoleRate(index: number) {
    var role = this.merchantRoles[index];
    role.edit = true;
  }
  roleRowClick(row: any, column: any, event: any) {
    if (
      column.property &&
      column.property === "distributionRate" &&
      event.target.className == "distribution"
    ) {
      row.edit = true;
      this.$nextTick(() => {
        event.target.nextSibling.children[0].focus();
      });
    }
  }
  async roleDistributionRateChange(event: any, row: any) {
    console.log(event);
    console.log(row);
    var rate = parseFloat(event.target.value);
    if (!rate || rate > 100 || rate < 0)
      return void this.$message.error("请输入正确的分红比例");
    row.distributionRate = rate / 100;

    var roles = this.getRoles();

    var loading = this.$loading({ text: "保存中..." });
    try {
      var res = await this.$ajax.post(
        "/api/services/app/Merchant/SetRoles",
        this.getRoles()
      );
      if (res.data.success) {
        row.edit = false;
        this.$notify.success("保存成功");
        // this.readerRoles();
      }
    } catch (error) {}
    loading.close();
  }
}
