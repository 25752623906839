




























import { Component, Vue, Watch } from "vue-property-decorator";
import utils from "@/libs/utils";

@Component
export default class MerchantStaffDialog extends Vue {
  dialogFormVisible: boolean = false;
  formData: any = {
    memberId: "",
    name: ""
  };
  loading: boolean = false;
  rules: any = {
    memberId: [{ required: true, message: "请选择会员", trigger: "change" }]
  };

  memberSearchLoading: boolean = false;
  memberItems: any[] = [];
  async readerMemberOptions(query: string) {
    this.memberSearchLoading = true;
    try {
      var res = await this.$ajax.post(
        "/api/services/app/Member/QueryPagedList",
        {
          page: 1,
          size: 10,
          keywords: query
        }
      );
      this.memberItems = res.data.result.items;
    } catch (error) {}
    this.memberSearchLoading = false;
  }
  show() {
    this.dialogFormVisible = true;
    this.formData = {
      memberId: "",
      name: ""
    };
  }
  hide() {
    this.dialogFormVisible = false;
  }
  submit() {
    (this.$refs["roleForm"] as any).validate(async (valid: boolean) => {
      if (valid) {
        var member = this.memberItems.find(m => m.id == this.formData.memberId);
        var staff = {
          memberId: this.formData.memberId,
          name: member.name
        };
        this.$emit("confirm", staff);
      }
    });
  }
}
